<template>
    <div class="permission-page">
        <router-link class="back-previous-screen" to="/business/settings/business-settings/permission-groups">
            {{ "< " + $t("Back to [Previous Screen]", { previousScreen: $t("Business Settings") }) }}
        </router-link>

        <CustomLoader v-if="loading" height="400px" />

        <template v-else>
            <BusinessPermissionGroupForm
                class="mb-7"
                ref="permissionGroupForm"
                :title="$t('Create Permission Group')"
                :submitted="submitted"
                :product-areas-list="businessProductAreasList"
                :countries-list="businessCountriesList"
                :banks-list="businessBankList"
            />

            <PermissionGroupUsers
                ref="permissionGroupUsers"
                :users-data-list="businessUsersData"
            />

            <div class="permission-page__footer">
                <button class="main-btn-outline btn" @click="handleCancel">{{ $t('Cancel') }}</button>

                <button
                    :data-kt-indicator="submitLoading ? 'on' : 'off'"
                    :disabled="submitLoading"
                    class="btn main-btn"
                    @click="handleSave">
                    <span class="indicator-label"> {{ $t("Save") }} </span>

                    <span class="indicator-progress">
                        {{ $t("pleaseWait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader.vue";
import BusinessPermissionGroupForm from "@/buying-teams/pages/business/settings/business/components/BusinessPermissionGroupForm.vue";
import PermissionGroupUsers from "@/buying-teams/pages/bank/settings/bank/components/permission/PermissionGroupUsers.vue";
import PermissionGroupForm from "@/buying-teams/pages/bank/settings/bank/components/permission/PermissionGroupForm.vue";
import NotificationService from "@/buying-teams/services/NotificationService";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";
import { filterAllowedBanks } from "@/store/models/business/permissions/BusinessPermissionGroup";

export default {
    name: "BusinessAddPermissionGroup",
    components: {
        PermissionGroupForm,
        PermissionGroupUsers,
        BusinessPermissionGroupForm,
        CustomLoader
    },
    data() {
        return {
            submitted: false,
            submitLoading: false,
            loading: true,
            businessUsersData: [],
        }
    },
    computed: {
        currentUser() {
            return store.getters.currentUser;
        },
        businessBankList() {
            return store.getters.businessBanks;
        },
        businessCountriesList() {
            return store.getters.businessCountries
        },
        businessProductAreasList() {
            return store.getters.businessProductAreas
        },
        businessBanksListIds() {
            return (this.businessBankList || []).map(c => c.id);
        }
    },
    mounted() {
        if (this.currentUser && !this.currentUser.can(BusinessUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)) {
            this.$router.push('/business/settings/business-settings/overview');
        }

        setCurrentPageBreadcrumbs({ title: `${this.$t("hi")} ${this.currentUser.first_name},  <span>${this.$t("welcomeBack")}</span>` });

        this.handleGetUsersList();
    },
    methods: {
        handleCancel() {
            this.$router.push({ name: 'business-settings-permission-groups' });
        },
        handleGetUsersList() {
            store.dispatch('getPermissionGroupUsersList').then(res => {
                this.businessUsersData = res;
            }).finally(() => {
                this.loading = false;
            })
        },
        handleSave() {
            const permissionGroupForm = this.$refs.permissionGroupForm;
            const permissionGroupUsers = this.$refs.permissionGroupUsers;
            this.submitted = true;

            if (permissionGroupForm.isFormValid) {
                this.submitLoading = true;
                const payload = {
                    ...permissionGroupForm.formData,
                    users: permissionGroupUsers.selectedUsersIds
                }
                payload.banks = filterAllowedBanks(permissionGroupForm.formData.banks, this.businessBanksListIds);

                store.dispatch('addBusinessPermissionsGroup', payload)
                    .then(() => {
                        NotificationService.swalNotify({ confirmButtonText: this.$t("OK") });
                        this.$router.push({ name: 'business-settings-permission-groups' });
                    })
                    .finally(() => {
                        this.submitLoading = false;
                    })
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth", });
            }

        }
    }
}
</script>

<style lang="scss">
.permission-page {
    &__footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 34px;
        padding-right: 40px;
        padding-bottom: 5px;
    }
}
</style>
